import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { isBrowser } from "../../../context/helpers";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useDetectElementLoaded from "../../../hooks/useDetectElementLoaded";
import { usePoints, useAuthenticateLL } from "../../../hooks/useALRewards";

import SectionTitle from "../SectionTitle";
import "./loyaltylion-common.scss";
import "./myRewards/loyaltylion-rewards.scss";
import * as Styles from "./myRewards/styles.module.scss";

const REWARDS_ELEMENT_ID = "ll-rewards";

function MyRewards({ email }) {
  useAuthenticateLL(email);
  const dict = useLocalizedSentenceDict();
  const [points, approvedPointsRef] = usePoints();

  // Callback for updating the dom structure when LL loads
  const onDOMStructureUpdated = useCallback(() => {
    const claimedRewardsEl = document.getElementsByClassName("ll-claimed-rewards")[0];

    // Go through claimed rewards and update the elements
    const claimedRewards = claimedRewardsEl.getElementsByClassName("lion-claimed-reward-item");
    const claimedRewardsToRemove = [];
    if (claimedRewards?.length > 0) {
      [...claimedRewards].forEach((reward) => {
        const isDonation = !!reward.getElementsByClassName("lion-icon__reward--custom")[0];
        if (isDonation) {
          claimedRewardsToRemove.push(reward);
          return;
        }

        // Set the text on the view code button
        const viewCodeButtonTextEl = reward.getElementsByClassName(
          "lion-claimed-reward-item__right"
        )[0];
        if (viewCodeButtonTextEl) {
          viewCodeButtonTextEl.innerHTML = dict.get("View Code");
        }
      });

      // Don't show claimed donations
      claimedRewardsToRemove.forEach((rewardToRemove) => {
        rewardToRemove.remove();
      });
    }
  }, [dict, points]);

  const isLoaded = useDetectElementLoaded({
    elementID: REWARDS_ELEMENT_ID,
    detectChildren: true,
    onNodesAdded: onDOMStructureUpdated,
    stopObservingAfter: 30 * 1000,
  });

  useEffect(() => {
    if (isLoaded && isBrowser && points) {
      onDOMStructureUpdated();
    }
  }, [dict, isLoaded, points, onDOMStructureUpdated]);

  return (
    <>
      <span data-lion-points="approved" ref={approvedPointsRef} style={{ display: "none" }} />
      <div className={Styles.claimedSection}>
        <SectionTitle>Claimed rewards</SectionTitle>
        <div className="ll-claimed-rewards" data-lion-claimed-rewards-list />
      </div>
    </>
  );
}

MyRewards.propTypes = {
  email: PropTypes.string,
};

export default MyRewards;

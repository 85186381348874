import { useEffect } from "react";

import { useAuth } from "../../context/AuthProvider";
import { isBrowser, hasLoyaltyLion } from "../../context/helpers";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import ALRewardsHeader from "../ALRewardsHeader";
import ALRewardsNavigation from "../ALRewardsNavigation";
import MyRewards from "./alRewardsLoggedIn/MyRewards";
import RedeemPoints from "../RedeemPoints";

function ALRewardsLoggedIn() {
  const { accountDetails } = useAuth();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (isBrowser && hasLoyaltyLion()) {
      // add loyalty lion in isolation and remove predefined styles and fonts
      window.loyaltylion.configure({
        useClassIsolator: true,
        disableBundledCSS: true,
        disableBundledFonts: true,
      });
    }
  }, []);

  return (
    <div>
      {isMobile && <ALRewardsNavigation />}
      <ALRewardsHeader />
      {!isMobile && <ALRewardsNavigation />}
      <RedeemPoints />
      <MyRewards email={accountDetails?.email} />
    </div>
  );
}

export default ALRewardsLoggedIn;

import { PropTypes } from "prop-types";

import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";

import RedeemPointsImageDesktop from "../images/redeem-points-desktop.gif";
import RedeemPointsImageMobile from "../images/redeem-points-mobile.gif";
import SectionTitle from "./alRewards/SectionTitle";

import * as Styles from "./redeemPoints/styles.module.scss";

function RedeemPoints({ type = "" }) {
  const redeemPointsGif = useResponsiveWithHydrationFix(
    <img src={RedeemPointsImageMobile} alt="Redeem Points" />,
    <img src={RedeemPointsImageDesktop} alt="Redeem Points" />
  );

  return (
    <div className={`${Styles.redeemPoints} ${Styles[type]}`}>
      <SectionTitle>{`Redeem ${type === "luxe" ? "credits" : "points"} at checkout`}</SectionTitle>
      {redeemPointsGif}
      <p className="subtext">
        Find the input field at checkout and select &apos;Redeem&apos; to apply it to your purchase.
      </p>
    </div>
  );
}

RedeemPoints.propTypes = {
  type: PropTypes.string,
};
export default RedeemPoints;

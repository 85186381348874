// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--ddd02";
export var bold = "styles-module--bold--cbd9e";
export var center = "styles-module--center--05ff6";
export var h1 = "styles-module--h1--6f217";
export var h1Bold = "styles-module--h1--bold--fdb78";
export var h1Uc = "styles-module--h1--uc--dfa4a";
export var h2 = "styles-module--h2--86a36";
export var h2Bold = "styles-module--h2--bold--10d52";
export var h2Uc = "styles-module--h2--uc--eb312";
export var h3 = "styles-module--h3--3bb8b";
export var h3Bold = "styles-module--h3--bold--1c8cf";
export var h3Uc = "styles-module--h3--uc--74f9b";
export var h4 = "styles-module--h4--a5987";
export var h4Bold = "styles-module--h4--bold--0d9f6";
export var h4Uc = "styles-module--h4--uc--8c575";
export var header = "styles-module--header--f67dc";
export var headerHero = "styles-module--header-hero--4ccee";
export var header__button = "styles-module--header__button--3d480";
export var header__image = "styles-module--header__image--c1e16";
export var header__wrapper = "styles-module--header__wrapper--f62f6";
export var linkUnderline = "styles-module--link--underline--771a1";
export var microtext = "styles-module--microtext--0546e";
export var microtextBold = "styles-module--microtext--bold--db9b2";
export var microtextUc = "styles-module--microtext--uc--e5a7a";
export var strikethrough = "styles-module--strikethrough--f6a19";
export var strikethroughLg = "styles-module--strikethrough--lg--40a5b";
export var strikethroughSm = "styles-module--strikethrough--sm--09e12";
export var subtext = "styles-module--subtext--46178";
export var subtextBold = "styles-module--subtext--bold--9ac40";
export var subtextUc = "styles-module--subtext--uc--b5f14";
export var text = "styles-module--text--ded8e";
export var textBold = "styles-module--text--bold--60250";
export var textUc = "styles-module--text--uc--c466c";
export var titleL = "styles-module--title-l--24707";
export var titleM = "styles-module--title-m--d7d4f";
export var titleS = "styles-module--title-s--6a44a";
export var titleXl = "styles-module--title-xl--c58eb";
export var titleXs = "styles-module--title-xs--16fb6";
export var titleXxs = "styles-module--title-xxs--a4eda";
export var uc = "styles-module--uc--1492b";
export var underline = "styles-module--underline--6c3fd";
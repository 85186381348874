import { useAuth } from "../../context/AuthProvider";
import { useAuthenticateLL } from "../../hooks/useALRewards";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";

import ALRewardsLoggedOut from "./ALRewardsLoggedOut";
import ALRewardsLoggedIn from "./ALRewardsLoggedIn";

function Rewards() {
  const hydrationComplete = useIsHydrationComplete();

  const { accountDetails } = useAuth();
  useAuthenticateLL(accountDetails?.email);

  if (hydrationComplete) return accountDetails ? <ALRewardsLoggedIn /> : <ALRewardsLoggedOut />;
  return null;
}

export default Rewards;

import SEO from "../components/seo";
import RewardsPage from "../components/alRewards/Rewards";

function Rewards() {
  return (
    <>
      <SEO
        title="AL Rewards"
        description="Discover exclusive rewards and benefits with Ana Luisa's Loyalty Program. Earn points with every purchase and enjoy special perks such as early access to new collections, personalized styling tips, and exclusive promotions. Join now and elevate your jewelry experience with Ana Luisa's exquisite designs and exceptional customer service."
      />
      <RewardsPage />
    </>
  );
}
export default Rewards;

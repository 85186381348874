import { useAuth } from "../../../context/AuthProvider";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

import { ALButton } from "../../ALComponents";

import ClubALHeroDesktop from "../../../images/rewards/club-al-hero-signedout-desktop.jpg";
import ClubALHeroMobile from "../../../images/rewards/club-al-hero-signedout-mobile.jpg";
import ClubALLoggedOut from "../../../images/rewards/club-al-logged-out.jpg";

import * as Styles from "./header/styles.module.scss";

function Header() {
  const { showLogin } = useAuth();

  const { isMobile } = useDeviceDetect();

  return (
    <div className={Styles.header}>
      <div className={Styles.headerHero}>
        {isMobile ? (
          <img src={ClubALHeroMobile} alt="ClubALHeroMobile" />
        ) : (
          <img src={ClubALHeroDesktop} alt="ClubALHeroDesktop" />
        )}
      </div>

      <div className={Styles.header__wrapper}>
        <ALButton variant="tertiary" onClick={showLogin} className={Styles.header__button}>
          SIGN IN
        </ALButton>
        <div className={Styles.header__image}>
          {!isMobile && <img src={ClubALLoggedOut} alt="ClubALLoggedOut" />}
        </div>
      </div>
    </div>
  );
}

export default Header;
